import React, {FC} from 'react';
import {Col, Row} from 'react-bootstrap';
import capterra from '../../images/badges/capterra.png';
import front from '../../images/badges/front-runners.png';
import getapp from '../../images/badges/get-app.png';
import performer from '../../images/badges/high-performer.png';
import './styles.scss';
const BadgeSection: FC<{title?: string, titleColor?: string}> = ({title, titleColor}) => {
  const badges = [
    {img: capterra, alt: 'Capterra', linkTo: 'https://www.capterra.com/data-extraction-software/#shortlist'},
    {
      img: front,
      alt: 'Front Runners',
      linkTo:
        'https://www.softwareadvice.com/data-extraction/?elqTrackId=421cacfdfbc7446fa00598e4bff8e8c7&elqaid=6009&elqat=2',
    },
    {
      img: getapp,
      alt: 'GetApp',
      linkTo: 'https://www.getapp.com/business-intelligence-analytics-software/data-extraction/category-leaders/',
    },
    {img: performer, alt: 'High Performer', linkTo: 'https://www.g2.com/products/hexomatic/reviews'},
  ];

  return (
    <section className="section-container">
      <h2 className="f_size_26 f_700 t_color3 l_height45 mb-20 text-center" style={{color: titleColor ? titleColor : ''}}>
        {title || 'Here are some of the awards Hexomatic has received'}
      </h2>
      <Row className="m-auto text-center justify-content-center badge-wrapper">
        {badges.map(badge => (
          <Col sm={6} md={2} className="center">
            {' '}
            {/* <a aria-label={badge.alt} href={badge.linkTo} rel="nofollow noopener" target="_blank"> */}
              <img src={badge.img} alt={badge.alt} className="m-4" width='120px' height='120px' style={{height: 'auto'}}/>
            {/* </a> */}
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default BadgeSection;
