import React, {useState, useEffect} from 'react';
import topImg from '../../images/annual-discount/cd-top-img.svg';
import './styles.scss';

const Counter: React.FC<{date?: string; hours?: boolean; leftSeats?: number; light?: boolean; title?: string, className?: string, red?: boolean}> = ({
  date,
  hours,
  leftSeats,
  light,
  title,
  className,
  red
}) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(date || 'Apr 25 2023 07:00:00 GMT') - +new Date(new Date().toUTCString());
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: hours
          ? Math.floor(difference / (1000 * 60 * 60 * 24)) * 24 + Math.floor((difference / (1000 * 60 * 60)) % 24)
          : Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  return (
    //@ts-ignore
    timeLeft && (timeLeft.days || timeLeft.hours || timeLeft.minutes || timeLeft.seconds) ? (
      <div className={`countdown_container ${className ? className : ''} ${light ? 'light' : ''} ${red ? 'cd-red' : ''}`}>
        {title ? <div className={`countdown-title ${light ? 'light' : ''} ${red ? 'mb-1' : ''}`}>{title}</div> : null}
        {/* {red ? <div><img src={topImg} alt='' className='cd-top-img'/></div> : null} */}
        <div className="d-flex justify-content-center">
          {//@ts-ignore
          !hours && timeLeft.days ? (
            <div className="unit">
              <div className="number_container">
                {
                  //@ts-ignore
                  timeLeft.days
                }
              </div>
              <div className="label_container">Days</div>
            </div>
          ) : null}
          <div className={`unit ${!hours && timeLeft.days ? '' : 'hours'}`}>
            <div className="number_container">
              {
                //@ts-ignore
                timeLeft.hours
              }
            </div>
            <div className="label_container">Hours</div>
          </div>
          <div className={`unit ${!hours && timeLeft.days ? '' : 'hours'}`}>
            <div className="number_container">
              {
                //@ts-ignore
                timeLeft.minutes
              }
            </div>
            <div className="label_container">Minutes</div>
          </div>
          <div className={`unit ${!hours && timeLeft.days ? '' : 'hours'}`}>
            <div className="number_container">
              {
                //@ts-ignore
                timeLeft.seconds
              }
            </div>
            <div className="label_container">Seconds</div>
          </div>
          {typeof leftSeats === 'number' && (
            <div className="unit seats-left">
              <div className="number_container">
                {
                  //@ts-ignore
                  leftSeats
                }
              </div>
              <div className="label_container">Seats left</div>
            </div>
          )}
        </div>
      </div>
    ) : null
  );
};

export default Counter;
