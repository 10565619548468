import React, {FC} from 'react';
//import Select from "../../select";
import {AutomationCardsProps} from '../../types';
import {tagIcon, menuBtn, starIcon, newLabelIcon} from '../../../images/svgIcons';
import TranslateMarkdown from '../../../components/translation-markdown';
import './styles.scss';

const AutomationCards: FC<AutomationCardsProps> = ({title, icon, description, active, rate, type, is_new}) => {
  return (
    <div className="automation-card m-auto">
      <div className="automation-card-header">
        <div className="automation-card-icon">
          <img
            src={icon}
            alt={'Tool icon'}
            style={{
              width: title.toLocaleLowerCase().includes('human') ? '25px' : '',
              maxHeight: title.toLocaleLowerCase().includes('human') ? '25px' : '',
            }}
          />
        </div>
        <div className="w-100">
          {is_new && <span className="new-label-icon">{newLabelIcon}</span>}
          {title.toLocaleLowerCase().includes('human') && (
            <span className={`beta-label-icon ${is_new ? '' : 'top_12'}`}>BETA</span>
          )}

          <h5 className={`text-truncate ${is_new ? 'm-width' : ''}`}>{title}</h5>
          <h6 className="text-truncate">
            {type && type.name}
            {/* {credit_price ? `Credits: ${credit_price}` : ""}
            {credit_price && premium_credit_price ? " | " : ""}
            {premium_credit_price
              ? `Premium Credits: ${premium_credit_price}`
              : ""} */}
          </h6>
        </div>

        {/* <div className="automation-rating-block">
            <span className="automation-star-icon">{starIcon}</span>
            <span className="automation-progress-bar">
              <span className="automation-progress-bar-filled" style={{ width: `${(rate / 5) * 100}%` }}></span>
            </span>
            <span className="automation-rating">{rate}</span>
          </div> */}
        {/* <div className="automation-header-left">
            <div className={`automation-tag-icon ${active ? '' : 'disabled'}`}>{tagIcon}</div>
            <div className="automation-menu-btn">
              {menuBtn}
              <Select showLeft icon={menuBtn} options={[]} onChange={() => { }} />
            </div>
          </div> */}
      </div>
      <div className="automation-card-body">
        <p>
          <TranslateMarkdown
            text={description && description.length > 150 ? `${description.slice(0, 90)}...` : description}
          />
        </p>
      </div>
    </div>
  );
};

export default AutomationCards;
