import React, { FC } from "react";
import htmlParser from "react-markdown/plugins/html-parser";
import MarkDown from "react-markdown/with-html";
import breaks from "remark-breaks";

interface ITranslateMarkdown {
  text: string;
}

const parseHtml = htmlParser({
  isValidNode: (node: any) => node.type !== "script",
  processingInstructions: [
    /* ... */
  ],
});

const TranslateMarkdown: FC<ITranslateMarkdown> = ({ text }) => (
  <MarkDown
    source={text}
    astPlugins={[parseHtml]}
    escapeHtml={false}
    disallowedTypes={true ? ["paragraph"] : []}
    unwrapDisallowed={true}
    plugins={[breaks]}
  />
);

export default TranslateMarkdown;
